// Skeleton for API calls
import axios from "axios"
import { successfulTrades } from "./successfulTrades"

const server = axios.create({
  baseURL : 'http://localhost:3002',
  headers : {

  }
})


// Orderbook API
export const getOrderbook = (asset:string, setOrderbook:Function): void => {
  const orderbookPromise =  server.get(`/orderbook/${asset}/`)
  orderbookPromise.then(response => {
    console.log(response.data)
    setOrderbook(response.data)
  })
  .catch(err => {
    console.log(err)
  })
}

interface trade {
  asset: string,
  side: string,
  type?: string,
  quantity: number,
  price: number,
  notional: number
}

// Trade API
export const sendTrade = (data: trade, setResponse: Function) : void => {
  const tradePromise = server.post('/trade', data)
  tradePromise.then(response => {
    console.log(response)
    setResponse(response)
    if(response.status === 200){
      successfulTrades.push(response.data)
    }
  })
  .catch(err => {
    console.log(err)
  })
}