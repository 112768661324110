import {useState, useEffect} from 'react'
import { getOrderbook, sendTrade } from '../api/api'

import '../styles/orderbook.css'
import { ArrowRightCircleIcon } from '@heroicons/react/24/solid'
import { successfulTrades } from '../api/successfulTrades'
import btc from '../api/btc_orderbook.json'
import eth from '../api/eth_orderbook.json'
import { v4 as uuidv4 } from 'uuid';

//larger component to house all of the different modules
const Orderbook = ({}) => {

  //api data state
  const [orderbookData, setOrderbookData] = useState({})

  //for changing between ETH and BTC
  const [asset, setAsset] = useState('BTC')

  //for effective order form
  const [orderItem, setOrderItem] = useState(null)

  //for tracking trades made
  const [trades, setTrades] = useState([])


  //for the trades table to retrieve data
  const getTrades = () => {
    return trades
  }

  //for auto populate order form to get order info
  const getOrderItem = () => {
    return orderItem
  }

  //for tables to get which asset it is on to help auto populate
  const getAsset = () => {
    return asset
  }


  //when ever the user changes between BTC and ETH, get new data
  useEffect(() => {
    if(asset === 'BTC'){
      setOrderbookData(btc)
    }else if(asset === 'ETH'){
      setOrderbookData(eth)
    }
  }, [asset])

  
  return(
    <div className='orderbooks-wrapper'> {orderbookData ? 
      <div>
        <button className='asset-select' onClick={() => asset === 'BTC' ? setAsset('ETH') : setAsset('BTC')}>
          <span className={asset === 'BTC' ? 'active' : 'disabled'}>BTC</span> | 
          <span className={asset === 'ETH' ? 'active' : 'disabled'}> ETH</span>
        </button>
        <div className='tables-wrapper'>
          <AskBidTable items={orderbookData.asks} setOrderItem={setOrderItem} getAsset={getAsset} title='ASKS' side='buy' />
          <AskBidTable items={orderbookData.bids} setOrderItem={setOrderItem} getAsset={getAsset} title='BIDS' side='sell' />    
          <div className='form-wrapper'>
            <OrderForm getOrderItem={getOrderItem} defaultAsset={asset} getTrades={getTrades} setTrades={setTrades}/>
          </div>
      </div>
        <div className='trades-wrapper'>
          <TradeLog getTrades={getTrades}/>
        </div>
      </div>
       : <h1>Loading</h1> }
      
    </div> 
  
  )
}

//table component for the asks and bids
//Takes in an array of 2 item number arrays, a function to set a preload order, a function to get which asset it belongs to, a table title, and a side for the order
const AskBidTable = ({items, setOrderItem, getAsset, title, side}) => {

  //map the items to put into the table
  const mapItems = () => {
    const displayItems = items?.map((item, i) => 
        <OrderItem item={item} setOrderItem={setOrderItem} asset={getAsset()} side={side} key={i} />
      )
    return displayItems
  }

  return (<table className='orders-wrapper'>
    <caption><strong>{title}</strong></caption>
    <thead>
      <tr>
        <th>PRICE</th>
        <th>QUANTITY</th>
      </tr>
    </thead>
    <tbody>
      {mapItems()}
    </tbody>
  </table>)
}

//table item for asks and bids tables
//Takes in an item array that consists of 2 number, a function to preload that order into the form, an asset string for the preload, and a side string for the preload
const OrderItem = ({item, setOrderItem, asset, side}) => {
  return(
    <tr>
      <td className='orderArrow'>
        <button 
        className='btn-clear'
        onClick={() => setOrderItem({
          "asset": asset,
          "side": side,
          "type": 'limit',
          "price": item[0],
          "notational": (item[0] * item[1])
        })}><ArrowRightCircleIcon height={24} width={24} color='#FFFFFF' />
        </button>
      </td>
      <td className='order-item'> ${Number(item[0])} </td>
      <td className='order-item'> {Number(item[1])}</td>
    </tr>
  )
}


//componenet for the order form, takes in a function to get new preload items, a function to get trades for help in setting trades, and a function to set trades
const OrderForm = ({getOrderItem, getTrades, setTrades}) => {

  //state variables to control data for request body
  const [asset, setAsset] = useState('BTC')
  const [side, setSide] = useState('buy')
  const [type, setType] = useState('limit')
  const [quantity, setQuantity] = useState( 0)
  const [price, setPrice] = useState(0)
  
  //state variables to send message to user if error
  const [submitMessage, setSubmitMessage] = useState('')

  //function to assign proper variables to the state variables when preload comes in
  const preloadOrder = () => {
    const order = getOrderItem()
    if(order != null){
      setAsset(order.asset)
      setSide(order.side)
      setType('limit')
      setQuantity(0)
      setPrice(order.price)
    }
  }

  //check to see if there is a new preload item
  useEffect(() => {
    preloadOrder()
  }, [getOrderItem()])

  //POST request body
  const data = {
    "asset": asset,
    "side": side,
    "type": type,
    "quantity": quantity,
    "price": price,
    "notional": type === 'limit' ? price * quantity : quantity,
    "id": uuidv4(),
    "timestamp": Date.now()

  }

  //checks to make sure the user entered info properly and then sends post request
  const handleSubmit = () => {

    if(quantity <= 0){
      setSubmitMessage("Error: Quantity must be greater than 0")
    }else if(price <= 0 && type === 'limit'){
      setSubmitMessage("Error: Price must be greater than 0")
    }else{
      setSubmitMessage("Order placed successfully")
      setQuantity(0)
      setPrice(0)
      setTrades([... getTrades(), data])

      setTimeout(() => {
        setSubmitMessage(null)
  
      }, 5000)
    }
    
  }

  //checks if order type is changes to avoid 422 status for havin price when its a market order 
  useEffect(() => {
    const resetBasedOnOrderType = () => {
      if(type === 'market'){
        setPrice(0)
      }
    }

    resetBasedOnOrderType()
  }, [type])

  
  return (
    <div className='order-form-wrapper'>
      {submitMessage ? 
        <h3>{submitMessage}</h3>
        :
        <></>
      }
      <h2>ORDER FORM</h2>
        <button className='asset-select' onClick={() => asset === 'BTC' ? setAsset('ETH') : setAsset('BTC')}>
          <span className={asset === 'BTC' ? 'active' : 'disabled'}>BTC</span> | 
          <span className={asset === 'ETH' ? 'active' : 'disabled'}> ETH</span>
        </button>
        <button className='asset-select' onClick={() => side === 'buy' ? setSide('sell') : setSide('buy')}>
          <span className={side === 'buy' ? 'active' : 'disabled'}>BUY</span> | 
          <span className={side === 'sell' ? 'active' : 'disabled'}> SELL</span>
        </button>
        <button className='type-select'
         onClick={() => type === 'market' ?
          setType('limit') :
          setType('market')}>
          <span className={type === 'limit' ? 'active' : 'disabled'}>LIMIT</span>
          <span className={type === 'market' ? 'active' : 'disabled'}> MARKET</span>
        </button>

        <label className='form-label'>QUANTITY:
          <input type='number' value={quantity} onChange={(e) => setQuantity(e.target.value)} />
        </label>

        { type === 'limit' ? <label className='form-label'> PRICE: $
          <input type='number' value={price} onChange={(e) => setPrice(e.target.value)} />
        </label> : <></>}
        <button className='submit' onClick={() => handleSubmit()}>SUBMIT</button>
        
    </div>
  )
}

//table to see trades made
const TradeLog = ({getTrades}) => {

  const [trades, setTrades] = useState([])
  
  //checks to see if there are new trades from parent object
  useEffect(() => {
    setTrades(getTrades())

  }, [getTrades()])
  

  //maps the trade items
  const displayTrades = () => { 
    if(trades.length > 0){
      return trades?.map((trade, i) => 

        <TradeItem trade={trade} key={i} />
      )
    }else{
      return <tr><td>Loading...</td></tr>
    }
  }


  return (
    <div className='trades-table-wrapper'>
      <table>
        <thead>
          <tr>
            <th>DATE</th>
            <th>ASSET</th>
            <th>SIDE</th>
            <th>TYPE</th>
            <th>QUANTITY</th>
            <th>PRICE</th>
            <th>TOTAL</th>
          </tr>
        </thead>
        <tbody>
          {displayTrades()}
        </tbody>
      </table>
    </div>
  )
  

  
}

//Component for the trade table
const TradeItem = ({trade}) => {
  if(trade){
  return(
    <tr>
      <td>{new Date(trade.timestamp).toLocaleString()}</td>
      <td>{trade.asset}</td>
      <td>{trade.side}</td>
      <td>{trade.type}</td>
      <td>{trade.quantity}</td>
      <td>{trade.type === 'limit' ? trade.price : "N/A"}</td>
      <td>{trade.type === 'limit' ? trade.notional : "N/A"}</td>
    </tr>
  )
  }else{
    return <tr><td>Loading...</td></tr>
  }
  

}

export default Orderbook
