import React from 'react'
import Logo from '../Nascent-logo.png'
import {UserCircleIcon} from '@heroicons/react/24/solid'
import '../styles/landing.css'

const Header = () => {
  return (
    <div className='header'>
      <div className="logo">
      </div>
      <div className="title">
        <img src='/DWDLogos.png' />
      </div>
      <div className="icon">
        {<UserCircleIcon height={100} width={100} color="#FFFFFF" />}
      </div>
    </div>
  )
}

export default Header