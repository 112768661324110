import {React} from 'react'
import Header from '../components/Header'
import Orderbook from '../components/Orderbook'

//Landing page to help with page architecture in case this needs to be build upon further. 
const LandingPage = () => {


  return (
    <div>
      <Header />
      <div className="body-wrapper">
        <Orderbook />
      </div>
    </div>
  )
}

export default LandingPage
