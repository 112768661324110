import React from 'react';
import './App.css';
import LandingPage from './pages/LandingPage';

function App() {
  return (
    <div className="App">
      <LandingPage />
    </div> 
  );
}

export default App;
